import React from 'react';

import Logo from '../atom/Logo'

const Footer = () => {

  return (
      <div className="footer">

        <div className="footer__header">
          <Logo />
        </div>

        <div className="footer__container">

          <p>"Lieber Leere im Raum, als Leere im Kopf"</p>
          <ul className='footer__links'>
            <li><a href="/#">Datenschutzerklärung</a></li>
            <li><a href="/#">Impressum</a></li>
          </ul>

          <p className='copyright'>© 2022</p>

        </div>

      </div>
  );
}

export default Footer;
