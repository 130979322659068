import React from 'react';

const Logo = () => {
  return (
    <div className="logo">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="262" height="108" viewBox="0 0 262 108">
            <g id="Gruppe_6202" data-name="Gruppe 6202" transform="translate(-764 -2240.348)">
              <g id="Gruppe_6200" data-name="Gruppe 6200" transform="translate(765 2240)">
                <text id="Faller_" data-name="Faller " transform="translate(0 61.348)" fill="#fff" fontSize="72" fontFamily="BastligaOne, Bastliga One"><tspan x="0" y="0">Faller </tspan></text>
                <text id="Jansen" transform="translate(136 61.348)" fill="#fff" fontSize="72" fontFamily="BastligaOne, Bastliga One"><tspan x="0" y="0">Jansen</tspan></text>
              </g>
              <text id="Visual_Design_Coding" data-name="Visual Design &amp; Coding" transform="translate(793 2327.348)" fill="#e9ff43" fontSize="12" fontFamily="AvenirNext" fontWeight="800" letterSpacing="0.2em"><tspan x="0" y="0">VISUAL DESIGN &amp; CODING</tspan></text>

              
            </g>
        </svg>
      </div>
    </div>
  );
}

export default Logo
