import React from 'react';
import Navbar from "../components/element/Navbar";
import TextQuote from "../components/atom/TextQuote";
import ContactForm from '../components/element/ContactForm';
import Footer from '../components/module/Footer';
// import MacStage from '../components/module/MacStage';

function HomePage() {

  return (
      <div className="HomePage">
        <div className="App">
          <Navbar/>
          <div className="main">
            <div className="section second">
              <TextQuote />
            </div>
            {/*<div className="section fifth">*/}
            {/*  <MacStage />*/}
            {/*</div>*/}
            <div className="section contact">
              <ContactForm />
            </div>
            <Footer />
          </div>
        </div>
      </div>
  );
}

export default HomePage;
