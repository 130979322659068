import React from 'react';
import './App.sass';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import HomePage from "./pages/HomePageShort";
import {Helmet} from "react-helmet";

function App() {

  return (
      <Router>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Faller & Jansen - Visual Communication</title>
            <meta name="description" content="Wir helfen Startups und Digital Agenturen ihre Projekte bestmöglich zu realisieren." />
        </Helmet>
        <Routes>
          <Route path={"/"} element={<HomePage/>} />
        </Routes>
      </Router>
  );
}

export default App;
